@media (max-width:320px){
    
.modals {
     /* Ocultar el modal por defecto */
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.9);
    
}

/* Estilos del contenido del modal */
.modals-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 0px;
    background-color: #fefefe;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    background-color: rgba(22, 22, 22, 0.9);
    
}

/* Estilos del carrusel */
.carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    
    
}



.slidex {

   height: 50vh;

  

}


/* Estilos para los botones de cambio de imagen */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: rgb(204, 178, 178);
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
    background-color: rgba(95, 90, 90, 0.8);
}

/* Estilo para el botón de cierre */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

  
}


@media (min-width:768px){

    
.modals {
     /* Ocultar el modal por defecto */
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.9);
    
}

/* Estilos del contenido del modal */
.modals-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 0px;
    background-color: #fefefe;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    background-color: rgba(22, 22, 22, 0.9);
    
}

/* Estilos del carrusel */
.carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    
    
}



.slidex {

   height: 80vh;
   width: auto;

  

}


/* Estilos para los botones de cambio de imagen */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: rgb(204, 178, 178);
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
    background-color: rgba(95, 90, 90, 0.8);
}

/* Estilo para el botón de cierre */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}



  
}





