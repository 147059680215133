
.buttonxd {
width: 50%;
}

.btn:hover {
    background: #563434;
    
}

form input {
    width: 100%;
    height: 30px;
    margin: 10px 0;
    padding: 0 10px;
    border: 1px solid #ccc;
}

form .btn {
    width: 100%;
    border: none;
    cursor: pointer;
    margin:5px;
}

.location{
    width: 100%;
    border: 0;
    height: 400;
    /*border: 1px solid blue*/
}
.location iframe{
    width: 100%;
}
.contact-us{
    width: 80%;
    margin: auto;
}
.contact-col {
  flex-basis: 48%;
  margin-bottom: 10px; /* Reduced margin to bring the elements closer */
}

.name-lastname {
  display: flex;
  justify-content: space-between;
}

.contact-col div{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.contact-col div .fa{
    font-size: 28px;
    color: #00DA00;
    margin: 10px;
    margin-right: 30px;
}
.contact-col div p{
    padding: 0;
}
.contact-col div h5{
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
}

.contact-col div a{
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
    text-decoration: none;
}

.contact-col input,
.contact-col textarea {
  /* Adjust width as needed */
  width: 100%;
  padding: 15px;
  margin-bottom: 10px; /* Reduced margin to bring the elements closer */
  outline: none;
  border: 1px solid #ccc;
}

.section {
    display: grid;
    grid-template-columns: 1fr ;
    min-height: 90vh;
    width: 95vw;
    }

.content {
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media(max-width: 992px) {
    section {
        grid-template-columns: 1fr;
        width: 100%;
    }
    .image {
        height: 100vh;
    }
    .content {
        height: 100vh;
    }
    .contact-col input, .contact-col textarea {
    width: 300px;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
    justify-content: center;
}

.row{
    width: 100%;
   padding-left: 30px;
}
.name-lastname {
    flex-direction: column; 
  }
.buttonxd {
    padding-top: 50px;
  }
}



