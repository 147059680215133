* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* border: 1px solid red */
}

.offer {
    background: radial-gradient(#fff, #ffd6d6);
    margin-top: 80px;
    padding: 30px 0;
}

.offer1 {
    background: radial-gradient(#94e584, #86f15b);
    margin-top: 80px;
    padding: 30px 0;
}

.col-2 .offer-img {
    padding: 50px;
}

.minicontainers {
    max-width: 1080px;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;
}
.row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;

}
.col-2 {
    flex-basis: 50%;
    min-width: 300px;
}

.col-2 img {
    max-width: 100%;
}

.col-2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 25px 0;
}

.col-2 p,
h3,
.btn,
input,
select,
h4 {
    margin: 20px;
}

