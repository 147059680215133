
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
   /* border: solid red 1px    */
}


body {
  margin: 0;
  padding: 0;
}

.player-wrapper{
  position: relative;
  padding-top: 56.25%;
}

.player-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 245, 0, 0.3); 
}


.react-player{
  position: absolute;
  top: 0;
  left: 0;
}

.react-player::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 245, 0, 0.3); 
}
