.sponsors {
    margin: 100px auto;
}

.col-5 img {
    width: 25%;
    cursor: pointer;
}

.rows{
    display: flex;
    align-items: center;
}