@import "./Styles/Home.css";
@import "./Styles/Offer.css";
@import "./Styles/About.css";
@import "./Styles/Contact.css";
@import "./Styles/Product.css";
@import "./Styles/Sponsors.css";
@import "./Styles/Foot.css";
@import "./Styles/Pricer.css";
@import "./Styles/modal.css";

 /* *{

   border: 1px solid red; 
}   */
img {
  max-width: 100%;
  height: auto;
}
.App {
text-align: center;
}

  .homeslidepic{
  width: 100%;
  }

body {
  max-width: 100%;
  overflow-x: hidden;
}


.carousel-container {
  position: relative;
}

@media (max-width: 1567px) {
  .homeslidepic{
    max-height: 855px;
  }
}
@media (max-width: 767px) {
  .carousel-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .brand{
    max-width: 250px;
    max-height: 60px;

  }
  .homeslidepic{
    max-height: 220px;
  }
}

.brand{
    margin: auto;
    width: 350px;
    height: 100px;
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.texts{
  text-decoration: none;
  color: black;
  cursor: pointer;

}

.image-overlay {
  position: relative;
  height: 50%;
}

.image-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 245, 0, 0.3); 
}

.navbar-bg-red {
  background-color: rgb(77, 211, 77);
}
