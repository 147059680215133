* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* border: 1px solid red */
}

body {
    font-family: 'Popins', sans-serif;
}

.header {
    background: radial-gradient(#fff, #ffd6d6);
}

.header .row {
    margin-top: 70px;
}

.container {
    max-width: 1300px;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;
}

.navbar {
    display: flex;
    align-items: center;
    padding: 20px;
}

nav {
    flex: 1;
    text-align: right;
}

nav ul {
    display: inline-block;
    margin-right: 20px;
}

nav ul li {
    display: inline-block;
    margin-right: 20px;
}

a {
    text-decoration: none;
    color: #555;
}

p {
    color: #555;
}

.row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;

}

.row2{
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
}

.col-2 {
    flex-basis: 50%;
    min-width: 300px;
}

.col-2 img {
    max-width: 100%;
}

.col-2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 25px 0;
}

.col-2 p,
h3,
.button,
input,
select,
h4 {
    margin: 20px;
}

.button {
    display: inline-block;
    background: #ff523b;
    color: #fff;
    padding: 8px 30px;
    margin: 30px 0;
    border-radius: 30px;
    transform: background 0.5s;
    text-decoration: none;
}

.button:hover {
    background: #563434;
}

.categorias {
    margin: 70px 0;
}

.col-3 {
    flex-basis: 30%;
    min-width: 250px;
    margin-bottom: 30px;
    transition: transform 0.5s;
}

.col-3 img {
    width: 100%;
}

.col-3:hover {
    transform: translateY(-5px);
}

.minicontainer {
    max-width: 1080px;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;
}

.col-4 {
    flex-basis: 25%;
    padding: 10px;
    min-width: 200px;
    margin: bottom 50px;
    font-size: 14px;
    transition: transform 0.5s;
}

.col-4::before,
.col-4::after {
    position: absolute;
    top:-.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
}

.col-4::before{
    content: attr(data-tolltip);
}
.col-4 img {
    width: 100%;
}

.col-4:hover {
    transform: translateY(-5px);
}

.titulo {
    text-align: center;
    margin: 0 auto 80px;
    position: relative;
    line-height: 60px;
    color: #555;
}

.titulo::after {
    content: '';
    background: #ff523b;
    width: 80px;
    height: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

h4 {
    color: #555;
    font-weight: normal;
}

.offer {
    background: radial-gradient(#fff, #ffd6d6);
    margin-top: 80px;
    padding: 30px 0;
}

.col-2 .offer-img {
    padding: 50px;
}

.small {
    color: #555;
}

.opiniones {
    padding-top: 100px;
}

.opiniones i {
    font-size: 34px;
    color: #ff523b;
}

.opiniones .col-3 {
    text-align: center;
    padding: 40px 20px;
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.5s;
}

.opiniones .col-3 img {
    width: 50px;
    margin-top: 20px;
    border-radius: 50%;
}

.opiniones .col-3:hover {
    transform: translateY(-10px);
}

.col-3 p {
    font-size: 12px;
    margin: 12px 0;
    color: #777;
}



.footer {
    background: #000;
    color: #8a8a8a;
    font-size: 14px;
    padding: 60px 0 20px;
}

.footer p {
    color: #8a8a8a;
}

.footer h3 {
    color: #fff;
    margin-bottom: 20px;
}

.footer-col-1,
.footer-col-2,
.footer-col-3,
.footer-col-4 {
    min-width: 250px;
    margin-bottom: 20px;
}

.footer-col-1 {
    flex-basis: 30%;
}

.footer-col-2 {
    flex: 1;
    text-align: center;
}

.footer-col-2 img {
    width: 180px;
    margin-bottom: 20px;
}

.footer-col-3,
.footer-col-4 {
    flex-basis: 12%;
    text-align: center;
}

ul {
    list-style: none;
}

.app-logo {
    margin-top: 20px;
}

.app-logo img {
    width: 140px;
}

.footer hr {
    border: none;
    background: #b5b5b5;
    height: 1px;
    margin: 20px 0;
}

.copyright {
    text-align: center;
}

.menu-icon {
    width: 28px;
    margin-left: 20px;
    display: none;
}


/*Productos*/

.row-2 {
    justify-content: space-between;
    margin: 100px auto 50px;
}

select {
    border: 1px solid #ff523b;
    padding: 5px;
}

select:focus {
    outline: none;
}

.button-pag {
    margin: 0 auot 80px;
}

.button-pag span {
    display: inline-block;
    border: 1px solid #ff523b;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}

.button-pag span:hover {
    background: #ff523b;
    color: #fff;
}


/*Productos*/


/*Producto Solitario*/

.producto-solo {
    margin-top: 80px;
}

.producto-solo .col-2 img {
    padding: 0;
}

.producto-solo h4 {
    margin-top: 20px 0;
    font-size: 22px;
    font-weight: bold;
}

.producto-solo select {
    display: block;
    padding: 10px;
    margin-top: 20px;
}

.producto-solo input {
    width: 50px;
    height: 40px;
    padding-left: 10px;
    font: 20px;
    margin-right: 10px;
    border: 1px solid #ff523b;
}

input:focus {
    outline: none;
}

.img-mini-row {
    display: flex;
    justify-content: space-between;
}

.img-mini-col {
    flex-basis: 24%;
    cursor: pointer;
}


/*Producto Solitario*/

.button{
    display: flex;
    margin: 20px;
    justify-content: center;     
}

.button label{
    height: 15px;
    width: 15px;
    background-color: #b93030;
    margin: 0 4px;
    border-radius: 50%;
} 
.button label.active{
    width: 35px;
}
@media(max-width: 992px) {

.titulo {
    margin-top: 100px;
}

.titulo::after {
    content: '';
    background: #ff523b;
    width: 80px;
    height: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
}